import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

import { Layout } from "../components/Layout";
import { PageSeo } from "../components/Seo";
import { Container, Heading, Section } from "../components/Page";

const TestimonialsPage = () => {
  const { allMarkdownRemark: { edges } } = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: {fields: {collection: {eq: "testimonials"}}}
      sort: {fields: frontmatter___published}
    ) {
      edges {
        node {
          id
          frontmatter {
            authors
          }
          html
        }
      }
    }
  }
  `);
  return (
    <Layout>
      <PageSeo tabTitleSegments={["Testimonials"]} title="Testimonials" />
      <Container>
        <Heading>Testimonials</Heading>
        <Section className="max-w-narrow grid grid-cols-1 gap-16">
          {edges.map(({node: {id, frontmatter: {authors}, html }}) =>
            <Testimonial key={id} content={html} authors={authors} />)}
        </Section>
      </Container>
    </Layout>
  );
};

const Testimonial = ({authors, content}) => {
  return (
    <div>
      <div className="mb-4 text-center text-gray-600">
        <FontAwesomeIcon className="text-4xl" icon={faQuoteLeft} fixedWidth />
      </div>
      <div className="grid grid-cols-1 divide-y">
        <div className="italic pb-4 text-gray-600" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="pt-4">
          {authors}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsPage;
